var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.items != null && _vm.templates != null)?_c('div',[_c('EditDialog',{attrs:{"save-button-text":_vm.itemId === 'new' ? 'Create' : 'Save',"title":((_vm.itemId === 'new' ? 'New' : 'Edit') + " Integration"),"max-width":450,"transition":false},on:{"save":function($event){return _vm.loadItems()}},scopedSlots:_vm._u([{key:"form",fn:function(ref){
var onSave = ref.onSave;
var onError = ref.onError;
var needSave = ref.needSave;
var needReset = ref.needReset;
return [_c('IntegrationForm',{attrs:{"project-id":_vm.projectId,"item-id":_vm.itemId,"need-save":needSave,"need-reset":needReset},on:{"save":onSave,"error":onError}})]}}],null,false,3658369722),model:{value:(_vm.editDialog),callback:function ($$v) {_vm.editDialog=$$v},expression:"editDialog"}}),_c('ObjectRefsDialog',{attrs:{"object-title":"integration","object-refs":_vm.itemRefs,"project-id":_vm.projectId},model:{value:(_vm.itemRefsDialog),callback:function ($$v) {_vm.itemRefsDialog=$$v},expression:"itemRefsDialog"}}),_c('YesNoDialog',{attrs:{"title":"Delete Integration","text":"Are you sure you want to delete this Integration?"},on:{"yes":function($event){return _vm.deleteItem(_vm.itemId)}},model:{value:(_vm.deleteItemDialog),callback:function ($$v) {_vm.deleteItemDialog=$$v},expression:"deleteItemDialog"}}),_c('v-toolbar',{attrs:{"flat":""}},[_c('v-app-bar-nav-icon',{on:{"click":function($event){return _vm.showDrawer()}}}),_c('v-toolbar-title',[_vm._v("Integrations")]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.editItem('new')}}},[_vm._v("New Integration ")])],1),_c('div',{staticClass:"px-4 py-3"},[_vm._l(((_vm.aliases || [])),function(alias){return _c('div',{key:alias.id},[_c('code',{staticClass:"mr-2"},[_vm._v(_vm._s(alias.url))]),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.copyToClipboard(
               alias.url, 'The alias URL  has been copied to the clipboard.')}}},[_c('v-icon',[_vm._v("mdi-content-copy")])],1),_c('v-btn',{attrs:{"icon":""},on:{"click":function($event){return _vm.deleteAlias(alias.id)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1)],1)}),_c('v-btn',{attrs:{"color":"primary","disabled":_vm.aliases == null},on:{"click":function($event){return _vm.addAlias()}}},[_vm._v(" "+_vm._s(_vm.aliases == null ? 'Loading aliases...' : 'Add Alias')+" ")])],2),_c('v-data-table',{staticClass:"mt-4",attrs:{"headers":_vm.headers,"items":_vm.items,"items-per-page":Number.MAX_VALUE},scopedSlots:_vm._u([{key:"item.name",fn:function(ref){
               var item = ref.item;
return [_c('router-link',{attrs:{"to":("/project/" + _vm.projectId + "/integration/" + (item.id))}},[_vm._v(_vm._s(item.name)+" ")])]}},{key:"item.template_id",fn:function(ref){
               var item = ref.item;
return [_c('router-link',{attrs:{"to":("/project/" + _vm.projectId + "/templates/" + (item.template_id))}},[_c('code',[_vm._v(_vm._s(_vm.templates.find(function (t) { return t.id === item.template_id; }).name))])])]}},{key:"item.actions",fn:function(ref){
               var item = ref.item;
return [_c('div',{staticStyle:{"white-space":"nowrap"}},[_c('v-btn',{staticClass:"mr-1",attrs:{"icon":""},on:{"click":function($event){return _vm.askDeleteItem(item.id)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1),_c('v-btn',{staticClass:"mr-1",attrs:{"icon":""},on:{"click":function($event){return _vm.editItem(item.id)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)],1)]}}],null,false,2015648158)})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }