var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.items != null)?_c('div',[_c('EditDialog',{attrs:{"save-button-text":"Save","title":_vm.$t('editUser')},on:{"save":function($event){return _vm.loadItems()}},scopedSlots:_vm._u([{key:"form",fn:function(ref){
var onSave = ref.onSave;
var onError = ref.onError;
var needSave = ref.needSave;
var needReset = ref.needReset;
return [_c('UserForm',{attrs:{"project-id":_vm.projectId,"item-id":_vm.itemId,"need-save":needSave,"need-reset":needReset,"is-admin":true},on:{"save":onSave,"error":onError}})]}}],null,false,3557864013),model:{value:(_vm.editDialog),callback:function ($$v) {_vm.editDialog=$$v},expression:"editDialog"}}),_c('YesNoDialog',{attrs:{"title":_vm.$t('deleteUser'),"text":_vm.$t('askDeleteUser')},on:{"yes":function($event){return _vm.deleteItem(_vm.itemId)}},model:{value:(_vm.deleteItemDialog),callback:function ($$v) {_vm.deleteItemDialog=$$v},expression:"deleteItemDialog"}}),_c('v-toolbar',{attrs:{"flat":""}},[_c('v-btn',{staticClass:"mr-4",attrs:{"icon":""},on:{"click":function($event){return _vm.returnToProjects()}}},[_c('v-icon',[_vm._v("mdi-arrow-left")])],1),_c('v-toolbar-title',[_vm._v(_vm._s(_vm.$t('users')))]),_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary"},on:{"click":function($event){return _vm.editItem('new')}}},[_vm._v(_vm._s(_vm.$t('newUser')))])],1),_c('v-data-table',{staticClass:"mt-4",attrs:{"headers":_vm.headers,"items":_vm.items,"footer-props":{ itemsPerPageOptions: [20] }},scopedSlots:_vm._u([{key:"item.external",fn:function(ref){
var item = ref.item;
return [(item.external)?_c('v-icon',[_vm._v("mdi-checkbox-marked")]):_c('v-icon',[_vm._v("mdi-checkbox-blank-outline")])]}},{key:"item.alert",fn:function(ref){
var item = ref.item;
return [(item.alert)?_c('v-icon',[_vm._v("mdi-checkbox-marked")]):_c('v-icon',[_vm._v("mdi-checkbox-blank-outline")])]}},{key:"item.admin",fn:function(ref){
var item = ref.item;
return [(item.admin)?_c('v-icon',[_vm._v("mdi-checkbox-marked")]):_c('v-icon',[_vm._v("mdi-checkbox-blank-outline")])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticStyle:{"white-space":"nowrap"}},[_c('v-btn',{staticClass:"mr-1",attrs:{"icon":"","disabled":item.id === _vm.userId},on:{"click":function($event){return _vm.askDeleteItem(item.id)}}},[_c('v-icon',[_vm._v("mdi-delete")])],1),_c('v-btn',{staticClass:"mr-1",attrs:{"icon":""},on:{"click":function($event){return _vm.editItem(item.id)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)],1)]}}],null,false,489976516)})],1):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }